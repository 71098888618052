import { ApolloError, ApolloLink } from "@apollo/client";
import { ErrorHandler } from "@apollo/client/link/error";
import { Mutation } from "@apollo/client/react/components";
import { useEnvContext } from "context/EnvContext";
import {
  APP_CAREPROVIDER,
  QUERY_PROGRESS_FAILED,
  QUERY_PROGRESS_NOT_STARTED,
  QUERY_PROGRESS_PENDING,
  QUERY_PROGRESS_SUCCEED,
} from "core/consts";
import { AnyObject, QueryProgress } from "core/types";
import { useToast } from "dsl/atoms/ToastNotificationContext";
import { usePreventUnload } from "dsl/hooks";
import { ReactNode, useCallback, useState } from "react";
import { useTranslations } from "translations";
import { QuerySignature, logError } from "../utils";

const getMutationProgress = (
  started: boolean,
  loading: boolean,
  error: ApolloError,
) => {
  if (error != null) {
    logError(error);
    return QUERY_PROGRESS_FAILED;
  }
  if (loading) return QUERY_PROGRESS_PENDING;
  return started ? QUERY_PROGRESS_SUCCEED : QUERY_PROGRESS_NOT_STARTED;
};

export const GenericUpdateMutation = ({
  children,
  context,
  getVariables,
  mutation,
  onCompleted,
  onError,
  onStarted,
  refetchQueries,
  update,
}: {
  children: (
    submit: (...args: any[]) => any,
    queryProgress: QueryProgress,
    resetProgress: () => void,
    error: ApolloError,
  ) => ReactNode | null;
  context?: AnyObject;
  getVariables: (...args: any) => AnyObject;
  mutation: any;
  onCompleted?: (data: AnyObject) => void;
  onError?: (error: ApolloError | undefined) => void;
  onStarted?: () => void;
  refetchQueries?:
    | Array<QuerySignature | string>
    | (() => Array<QuerySignature | string>);
  update?: (proxy: any, result: AnyObject) => void;
}) => {
  const [started, setStarted] = useState(false);
  const resetProgress = useCallback(() => setStarted(false), []);
  return (
    <Mutation
      mutation={mutation}
      onCompleted={onCompleted}
      onError={onError}
      update={update}
      context={context}
    >
      {(updateMutation: any, { error, loading }: any) => {
        const submit = (...args: any[]) => {
          if (onStarted) onStarted();
          setStarted(true);
          return updateMutation({
            ...getVariables(...args),
            refetchQueries,
            awaitRefetchQueries: true,
          });
        };
        const queryProgress = getMutationProgress(started, loading, error);
        return (
          <EnhancedChildren queryProgress={queryProgress}>
            {children(submit, queryProgress, resetProgress, error)}
          </EnhancedChildren>
        );
      }}
    </Mutation>
  );
};

function EnhancedChildren({
  children,
  queryProgress,
}: {
  children: ReactNode;
  queryProgress: QueryProgress;
}) {
  usePreventUnload(queryProgress);
  return <>{children}</>;
}

export function useUpdate<T>(token?: string) {
  return useCallback((input: T) => ({ variables: { input, token } }), [token]);
}

export function useUpdateWithId<T>(id: number | string, token?: string) {
  return useCallback(
    (newEntity: T) => ({
      variables: {
        id: parseInt(`${id}`),
        input: { ...newEntity, id: parseInt(`${id}`) },
        token,
      },
    }),
    [id, token],
  );
}

export const useErrorHandler = (
  onError?: (errorHandler: ErrorHandler) => ApolloLink,
) => {
  const toast = useToast();
  const translations = useTranslations();
  const { app } = useEnvContext();

  const errorHandler = useCallback(
    (err: any) => {
      if (onError) onError(err);
      if (app === APP_CAREPROVIDER)
        toast({
          message: translations.auctionRequest.tryAgain,
          color: "danger",
        });
    },
    [onError, app],
  );

  return errorHandler;
};
